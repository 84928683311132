<template>
  <div class="text-center mt-5 pt-5">
    <img
      src="../../assets/images/400.svg"
      alt=""
      v-if="errrr"
      style="height: 50vh"
    />
    <ProgressSpinner v-else />
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      body: {
        userName: null,
        password: null,
      },
      isInvalid: false,
      disabledAdd: false,
      errrr: false,
    };
  },

  methods: {
    r() {
      return axios.create({
        baseURL: `${this.$baseURL}/api/`,
      });
    },
  },
  async mounted() {
    const type = this.$route.params.type;
    const code = this.$route.query.code;
    const oauth_token = this.$route.query.oauth_token;

    const oauth_verifier =
      "&oauth_verifier=" + this.$route.query.oauth_verifier || "";

    if (type == "twitter" || type == "t") {
      if (type == "twitter") {
        const u =
          `${this.$baseURL}/api/` +
          `auth/${type}/redirect?oauth_token=${oauth_token}${oauth_verifier}`;

        location.replace(u);
      } else {
        const user = this.$route.query.data;
        localStorage.homeHere =user;

        this.$toast.add({
          severity: "success",
          summary: this.$t("Done Successfully"),
          detail: this.$t("Login Successfully"),
          life: 3000,
        });
        location.reload();
      }
    } else {
      this.r()
        .get(
          code
            ? `auth/${type}/redirect?code=${code}`
            : `auth/${type}/redirect?oauth_token=${oauth_token}${oauth_verifier}`
        )
        .then(
          (res) => {
            this.disabledAdd = false;
            localStorage.homeHere = JSON.stringify(res.data.data);

            this.$toast.add({
              severity: "success",
              summary: this.$t("Done Successfully"),
              detail: this.$t("Login Successfully"),
              life: 3000,
            });
            location.reload();
          },
          (err) => {
            this.disabledAdd = false;
            this.errrr = true;
            this.$toast.add({
              severity: "error",
              summary: this.$t("Error"),
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
    }
  },
};
</script>
